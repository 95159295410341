import React, { useEffect, useState } from 'react';

import { Head } from '../components/head';
import { Nav } from '../components/header';
import { Hero } from '../components/hero_withSignUp';
import { Feature } from '../components/features';
import { Remortgage } from '../components/remortgage_example';
import { Simulation } from '../components/homeOwnership_simulation';
import { Faq } from '../components/faq';
import { Footer } from '../components/footer';

// markup
const IndexPage = () => {

  const [offset, setOffset] = useState(0);

  useEffect(() => {
    let debounceScroll = null
    const onScroll = () => {
      clearTimeout(debounceScroll);
      setTimeout(() => {
        setOffset(window.pageYOffset);
      }, 400);
    };
    onScroll();
    // clean up code
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  let scrolled = false;
  if(offset > 0) {
      scrolled = true;
  }

  return (
    <>
    <Head />
    <main>
      <title>Owned Collectively</title>
      <Nav 
        scrolled={scrolled}
      />
      <Hero />
      <Feature />
      <Remortgage />
      <Faq />
      <Simulation />
      <Footer />
    </main>
    </>
  )
}

export default IndexPage
