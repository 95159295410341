import React, { useState, useEffect } from 'react';

const NUMBER_OF_OWNER_DEATHS_PER_YEAR = 0.0534;
const NUMBER_OF_FIRST_TIME_BUYERS = 0.0559;

export const Simulation = () => {

  function weightedRand2(spec) {
    var i, sum=0, r=Math.random();
    for (i in spec) {
      sum += spec[i];
      if (r <= sum) return Number(i);
    }
  }

  useEffect(() => {
    const canvas = document.getElementById("game-of-ownership");
    const ctx = canvas.getContext("2d");
    const cellSize = 2,
          rows = 200,
          columns = 250;
    for (var row = 0; row < rows; row++) {
      for (var col = 0; col < columns; col++) {
        let greenBlue =  weightedRand2({0:0.63, 1:0.37});
        if(greenBlue) {
          console.log("green", greenBlue);
          ctx.fillStyle = "green";
        } else {
          console.log("blue", greenBlue);
          ctx.fillStyle = "blue";
        }
        var x = col * cellSize;
        var y = row * cellSize;
        var width = cellSize;
        var height = cellSize;
        ctx.fillRect(x, y, width, height);
      }
    }
    
  });


  const [example, setExample] = useState(true);

  const handleSwapExample = () => {
    setExample(!example);
  }

    return (
      <div className="px-4 py-8 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-8 bg-gray-200">
        <div className="grid gap-5 row-gap-8 lg:grid-cols-2">
          <div className="flex flex-col justify-center">
            <div className="max-w-xl">
              <div>
                <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-ocLightCyan">
                  Ownership Simulation
                </p>
              </div>
              <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
                What does ownership<br />        
                <span className="inline-block text-ocTeal">
                look like in the future?
                </span>
              </h2> 
              <p className="text-base mb-4 text-gray-700 md:text-md">
                If we continue on the path we are on with homeownership what does the future of society look-like?
              </p>
              <p className="text-base mb-4 text-gray-700 md:text-md">
                  Play with the values to see how home ownership will change.
              </p>
              <div className="flex flex-row justify-start">
                <p className="p-2 text-base text-ocPink md:text-sm bg-ocDarkBlue cursor-pointer" onClick={handleSwapExample}>
                    Uk housing market
                </p>
              </div>
              <div className="border-2 mb-8 border-ocDarkBlue flex flex-col flex-wrap lg:flex-row justify-start ">
               
                  <div className="m-4 bg-ocPink flex flex-col items-center">
                    <p className="p-2 text-base text-4xl">28mil.</p>
                    <p className="p-2 text-base text-sm">Homes</p>
                  </div>
                  <div className="m-4 bg-ocPink flex flex-col items-center">
                    <p className="p-2 text-base text-4xl">£296,000</p>
                    <p className="p-2 text-base text-sm">Avg. House price</p>
                  </div>
                  <div className="m-4 bg-ocPink flex flex-col items-center">
                    <p className="p-2 text-base text-4xl">£38,000</p>
                    <p className="p-2 text-base text-sm">Avg. Salary</p>
                  </div>
                  <div className="m-4 bg-ocPink flex flex-col items-center">
                    <p className="p-2 text-base text-4xl">63% </p>
                    <p className="p-2 text-base text-sm">Home ownership</p>
                  </div>
                  <div className="m-4 bg-ocPink flex flex-col items-center">
                    <p className="p-2 text-base text-4xl">53% </p>
                    <p className="p-2 text-base text-sm">House price increase in 10 years</p>
                  </div>
                
              </div>

            </div>
            <a
                className="w-64 inline-flex items-center font-semibold tracking-wider justify-center px-4 py-2 text-sm font-light tracking-wide text-ocBlue transition-colors duration-500 rounded-full hover:bg-ocTeal hover:text-gray-900 outline outline-ocTeal outline-offset-2 outline-2"
                href="/calculator"  
            >
                Try your own calculation 
                <svg
                className="inline-block w-3 ml-2"
                fill="currentColor"
                viewBox="0 0 12 12"
                >
                <path d="M9.707,5.293l-5-5A1,1,0,0,0,3.293,1.707L7.586,6,3.293,10.293a1,1,0,1,0,1.414,1.414l5-5A1,1,0,0,0,9.707,5.293Z" />
                </svg>
            </a>
          </div>
          <div className="">
            <div className="flex flex-col lg:flex-row items-start">
                <div className="lg:w-[600px] lg:h-[500px] rounded drop-shadow-xl bg-white p-4 lg:mr-4 mb-4 lg:mb-0">
                    <div className="pt-2 flex border-b border-gray justify-left">
                      <h3 className="text-lg font-light text-ocBlue">The simulation</h3>
                    </div>
                    <canvas id="game-of-ownership" width="500" height="400"></canvas>
                </div>
                  
            </div>
          </div> 
        </div>
      </div>
    );
  };